import { useBox, } from "@react-three/cannon";
import React from "react";
import { useState } from "react";
import CrashSound from "./assets/sounds/crash1.mp3";


const debug = false;
var n = 0;

export function ColliderBox({ position, scale, soundEffect }) {
  const [sound] = useState(() => new Audio(CrashSound));
  const [collided, setCollided] = useState(false);
  const MemoisedSound = React.useMemo(() => CrashSound, []);
  //const [soundEffect, setSoundEffect] = useState(new Audio());
  useBox(() => ({
    args: scale,
    position,
    type: "Static",
    onCollide: (e) => {
      //console.log("collided with", e);
      localStorage.setItem('question', 2);


      //get the sound to play only once
     
      if (n==0) {
        //sound.play();
        if(localStorage.getItem("sound") == 0){
          soundEffect.src = MemoisedSound;
          soundEffect.play();
        }
        setCollided(true);
        n=n+1;
      }


      
      
    
    }
  }));

  return (
    debug && (
      <mesh position={position}>
        <boxGeometry args={scale} />
        <meshBasicMaterial transparent={true} opacity={0.85} />
      </mesh>
    )
  );
}
