import React from 'react';
import Message1 from './assets/black.jpg';
import Message from './assets/bg-op.jpg';
import Glass from './assets/glass.png';
import CrashSound from "./assets/sounds/crash1.mp3";



const Preload = () => {
  const images = [Message1, Message, Glass];

  images.forEach(image => {
    const img = new Image();
    img.src = image;
  });

  const audio = new Audio();
  audio.src = CrashSound;

  return null;
};

export default Preload;


