import "./index.css";
import React,{useState,useEffect,Suspense,useRef} from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
//import { createRoot } from "react-dom/client";
import { Canvas } from "@react-three/fiber";
import { CameraShake,Html, useProgress } from "@react-three/drei"
import { Scene } from "./Scene";
import { Physics } from "@react-three/cannon";
import  Phone  from "./Phone";
import Lottie from "lottie-react";
import LoadingCar from "./assets/loading.json";
import Timer from "./Timer";
import PrizePot from "./PrizePot";
import SoundToggle from "./SoundToggle";
import Rotate from "./assets/rotate.json"
import FullPageImage from "./FullPageImage";
import FullPageLottie from "./FullPageLottie";
import EndMessage from "./EndMessage" 
import Explode from "./assets/explode.json"
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import GlassCrack from "./GlassCrack";
import FullPageBg from "./FullPageBg";
import Preload from "./Preload";
import DriveSound from "./assets/sounds/drive.mp3";
import Silence from "./assets/sounds/silence.mp3";




const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
  },
};

// bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#modal');


    
 








function App() {

    const [Loading, setLoading] = useState(true);
    const [Started,setStarted ] = useState(true);
    const [instruct,setInstruct] = useState(false);
    const [Crashed, setCrashed] = useState(false);
    const [Shake, setShake] = useState(false);
    const [Prize, setPrize] = useState(false);
    const [Message, setMessage] = useState(false);
    const [Fade, setFade] = useState(false);
    const [Enable, setEnable] = useState(1);
    const MemoizedPhone = React.useMemo(() => Phone, []);
    const MemoizedCrack = React.useMemo(() => GlassCrack, []);
    const [soundEffect, setSoundEffect] = useState(new Audio());
    var loadCount = 0;
    
    let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
     


  function Loader() {
    const { progress } = useProgress();
    
    //once both models are loaded, set loading to false
    if(progress==100){
      loadCount++;
      
      if(loadCount==1){
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
      //console.log('loaded' + loadCount + 'models')
      
      
    }
    
    return <Html center >
      <h3 style={{color:'white',display:'none'}}>{progress} % loaded</h3>
      
      </Html>
  }
  
  
  useEffect(() => {
    clearAllIntervals();
    clearAllTimeouts();
  }, []);
   


    

    const LoadingScreen = () => {
        if (!Loading) {return null;}
        else{
          return (
            <div className="loading">
                 <Lottie loop={true} animationData={LoadingCar} />
            </div>
        );
        }
        
        
    };


    const LandingScreen = () => {
      if(Started==true && instruct==false){
        return (
          <div className="landing">
            
            
            <div className="rotate">
                   <Lottie loop={true} animationData={Rotate} />
              </div>
            <img className="title" src="./title1.png" alt="title"/>
            <button className="start" onClick={() =>{
              //setStarted(false);
              setInstruct(true);
             localStorage.removeItem('question');
             localStorage.removeItem('score');
             localStorage.removeItem('sound');
              //restore default states
              setCrashed(false);
              setShake(false);
              setPrize(false);
              setMessage(false);
              setFade(false);
              
              
              
            } }>
              <img style={{borderRadius:'45px'}} src="./bg-button1.jpg" alt="start"/>
            </button>
          </div>
        )
      } else if(Started==true && instruct==true){
        return (
          <div className="ins">
            
            
            <img className="instruct" src="./ins.png" alt="start"/>
            <button className="start" style={{bottom:'12%'}} onClick={() =>{
              
              setStarted(false);
              setLoading(true);
              localStorage.setItem('score', 0);
              
            } }>
              <img style={{borderRadius:'45px'}} src="./bg-button2.jpg" alt="start"/>
            </button>
          
            
            
           
          </div>
        )
      }
      
    };


    var clearAllTimeouts = (function () {
      var noop = function () {},
          firstId = window.setTimeout(noop, 0);
      return function () {
          var lastId = window.setTimeout(noop, 0);
          //console.log('Removing', lastId - firstId, 'timeout handlers');
          while (firstId != lastId)
              window.clearTimeout(++firstId);
      };
    }());
    
    var clearAllIntervals = (function () {
      var noop = function () {},
          firstId = window.setInterval(noop, 0);
      return function () {
          var lastId = window.setInterval(noop, 0);
          //console.log('Removing', lastId - firstId, 'interval handlers');
          while (firstId != lastId)
              window.clearInterval(++firstId);
      };
    }());
   


    
  //checking for crash   
  useEffect (() => { 

    //keep checking for no of questions in local storage
    const interval = setInterval(() => {
      //console.log(localStorage.getItem('question'));
      if (localStorage.getItem('question') == 2) {
        //simulate crash
        //console.log("crash");
        //localStorage.removeItem('question');
        clearInterval(interval);
        setCrashed(true);
        openModal();
        

      }
      else{
        //remove local storage
        localStorage.removeItem('question');
      }
    }
    , 800);
    return () => clearInterval(interval);

    
  
    
   
  }, []);


  //disable context menu
  useEffect(() => {
    document.addEventListener('contextmenu', event => event.preventDefault());
  }, []);
   

    
   //camera shake config
   const config = {
    maxYaw: 0.1, // Max amount camera can yaw in either direction
    maxPitch: 0.01, // Max amount camera can pitch in either direction
    maxRoll: 0.2, // Max amount camera can roll in either direction
    yawFrequency: 0.2, // Frequency of the the yaw rotation
    pitchFrequency: 0.1, // Frequency of the pitch rotation
    rollFrequency: 0.1, // Frequency of the roll rotation
    intensity: 0.5, // initial intensity of the shake
    decay: true, // should the intensity decay over time
    decayRate: 0.85, // if decay = true this is the rate at which intensity will reduce at
    controls: undefined, // if using orbit controls, pass a ref here so we can update the rotation
  }

  useEffect(() => {
    if(Crashed==true){
    const shaketimer =  setTimeout(() => {
        setShake(true);
        clearTimeout(shaketimer);
      }, 700);
    }
  }, [Crashed,]);


  useEffect(() => {
    if(Shake==true){
    const fadetimer =  setTimeout(() => {
        //setPrize(true);
        setFade(true);
        clearTimeout(fadetimer);
      }, 8000);
    }
  }, [Shake]);


  useEffect(() => {
    if(Fade==true){
    const prizetimer =  setTimeout(() => {
        setPrize(true);
        
        clearTimeout(prizetimer);
      }, 5000);
    }
  }, [Fade]);

  useEffect(() => {
    if(Prize==true){
    const messagetimer =  setTimeout(() => {
        setMessage(true);
        setPrize(false);

        
        clearTimeout(messagetimer);
        //setFade(false);
        //localStorage.removeItem('question');
      }, 6000);
    }
  }, [Prize]);


  useEffect(() => {
    if(Message==true){
    const starttimer = setTimeout(() => {
      setFade(false);
      

      clearTimeout(starttimer);
    }, 9000);
    }
  }, [Message]);


  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('question');
      

      //clear all timeouts and intervals
      clearAllIntervals();
      clearAllTimeouts();

      //restore default states
      setStarted(false);
      setCrashed(false);
      setShake(false);
      setPrize(false);
      setMessage(false);
      setFade(false);
      setLoading(false);
      setInstruct(false);
      
      




    });
  }, [ clearAllIntervals, clearAllTimeouts, setStarted, setCrashed, setShake, setPrize, setMessage, setFade, setLoading, setInstruct]);



  

  

    return (

      <>
      <Preload />
      {Started ? (<LandingScreen />) : 
      
      (<>
        <LoadingScreen />
        
        
         <Canvas id="cv" >
         <Physics
           broadphase="SAP"
           gravity={[0, -2.6, 0]}
         >
           <Suspense  fallback={<Loader />}>
           <Scene soundEffect={soundEffect} />
           </Suspense>
         </Physics>
         {!Shake ? null : (<CameraShake intensity={0.6} />)}
         
          
       </Canvas>
       
       {Crashed ? null : (
        <>
        {Loading ? null : (<SoundToggle  soundEffect={soundEffect}/>)}
        </>
        )}
        
       {Crashed ? null : (
        <>
        {Loading ? null : (<Timer />)}
        </>
        )}
        {Crashed ? null : (
        <>
        {Loading ? null : (<PrizePot />)}
        </>
        )}
        {Crashed ? null : (
        <>
        {Loading ? null : (<MemoizedPhone />)}
        </>
        )}
        {Shake ? (<MemoizedCrack/>) : (null)}

        <TransitionGroup>
       {Shake ? (
       <CSSTransition
        key="lottie"
        timeout={500}
        classNames="lottie"
        >
        <FullPageLottie />
        </CSSTransition>
        ) : null}
      </TransitionGroup>

      <TransitionGroup>
       {Fade ? (
       <CSSTransition
        key="fade"
        timeout={4000}
        classNames="fade"
        >
        <FullPageBg />
        </CSSTransition>
        ) : null}
      </TransitionGroup>
        
        <TransitionGroup>
       {Prize ? (
       <CSSTransition
        key="prize"
        timeout={4000}
        classNames="prize"
        >
        <FullPageImage />
        </CSSTransition>
        ) : null}
      </TransitionGroup>

        
        <TransitionGroup>
       {Message ? (
       <CSSTransition
        key="message"
        timeout={4000}
        classNames="message"
        >
        <EndMessage />
        </CSSTransition>
        ) : null}
      </TransitionGroup>
        
    
        <div className="controls">
          
          <button >
            <img id="Up" className="c-img" src="./up.png" alt="up"/>
          </button>
          <button >
            <img id="Down" className="c-img" src="./down.png" alt="down"/>
          </button>
          <button >
            <img id="Left" className="c-img" src="./left.png" alt="left"/>
          </button>
          <button >
            <img id="Right" className="c-img" src="./right.png" alt="right"/>
          </button>
          
          
          
          
        </div>
    
        <div className="tools">
        <button >
            <img id="reset" className="c-img" src="./reset.png" alt="right"/>
          </button>
        <button >
            <img id="cam" className="c-img" src="./cam.png" alt="right"/>
        </button>
        </div>

        <div id="steer-left" className="steering-left"></div>
        <div id="steer-right" className="steering-right"></div>
         </>)
      
      
      
      }

        
       
                
                
      </>
      
            
        
      
    )
    
    
  }
  
  export default App
  
