import React,{useState,useEffect,Suspense,useRef} from 'react';
import DriveSound from "./assets/sounds/drive.mp3";
import Silence from "./assets/sounds/silence.mp3";


//sound-button component
const SoundToggle = ({soundEffect}) => {
  const [Enable, setEnable] = useState(1);
  //const [soundEffect, setSoundEffect] = useState(new Audio());
    
  
  const handleClick = () => {
    if (Enable == 1) {
      soundEffect.src = DriveSound;
      soundEffect.play();
      setEnable(0);
      localStorage.setItem("sound", 0);
      
    } else {
      soundEffect.pause();
      setEnable(1);
      localStorage.removeItem("sound");
     
    }
      
  };

  return (
    <button onClick={handleClick} 
    style={{
      position: "fixed",
      bottom: "20px",
      left: "20px",
      borderRadius: "50%",
      padding: "10px",
      background: "transparent",
      border: "2px solid white",
    }}
     >
      {Enable==0 ? (
        <img
          src="./sound.png"
          alt="Audio playing"
          style={{ width: "30px", height: "30px" }}
        />
      ) : (
        <img
          src="./no-sound.png"
          alt="Audio paused"
          style={{ width: "30px", height: "30px" }}
        />
      )}
    </button>
  );
};


export default SoundToggle;