import React from 'react';
import './FullPageImage.css'
import Glass from './assets/glass.png';

const GlassCrack = ({ }) => {
  return (
    <div className="FullPageImage" style={{backgroundImage: `url(${Glass})`}}>
      
    </div>
  );
}

export default GlassCrack;
